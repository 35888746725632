import DropDownMenu_IndentedMenuButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/IndentedMenuButtonSkin/IndentedMenuButtonSkin.skin';


const DropDownMenu_IndentedMenuButtonSkin = {
  component: DropDownMenu_IndentedMenuButtonSkinComponent
};


export const components = {
  ['DropDownMenu_IndentedMenuButtonSkin']: DropDownMenu_IndentedMenuButtonSkin
};


// temporary export
export const version = "1.0.0"
