import * as React from 'react';
import { IDropDownMenuProps } from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';
import MenuButton from '../../../../MenuButton/viewer/skinComps/IndentedMenuButtonNSkin/IndentedMenuButtonNSkin';
import styles from './IndentedMenuButtonSkin.scss';

const IndentedMenuButtonSkin: React.FC<IDropDownMenuProps> = props => {
  return <DropDownMenuBase {...props} styles={styles} Button={MenuButton} />;
};

export default IndentedMenuButtonSkin;
